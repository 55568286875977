<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <h2 class="c-grey-900 mB-20" style="display: inline-block">
          {{chapter}}
        </h2>
        <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <button class="btn btn-sm btn-success" @click="addLesson">
          <i class="ti-plus mL-5" style="color: #fff"></i>إضافة درس
        </button>
        <div id="dataTable_wrapper" class="dataTables_wrapper">
          <form @submit="checkForm">
            <div class="form-group row pB-30 pT-30">
              <div class="col-sm-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="lessonId"
                  placeholder="رقم الدرس"
                />
              </div>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="lessonName"
                  placeholder="إسم الدرس"
                />
              </div>
              <div class="col-sm-2">
                <button
                  type="submit"
                  class="btn btn-primary mL-10"
                  :disabled="loading"
                >
                  {{ $t("search") }}
                </button>
              </div>
            </div>
          </form>
          <table
            id="dataTable"
            class="table table-bordered dataTable table-hover"
            role="grid"
            aria-describedby="dataTable_info"
            style="width: 100%"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  {{ $t("name") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 عدد الأسئلة
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 30px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  تعديل
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 110px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  عرض الأسئلة
                </th>
              </tr>
            </thead>
            <tbody v-show="!loading" v-for="item in lessons" :key="item.id">
              <tr role="row" class="odd">
                <td class="sorting_1">{{ item.id }}</td>
                <td>
                  {{item.name }}
                </td>
                <td v-if="item.questions_count > 0">
                  {{ item.questions_count}} سؤال 
                </td>
                <td v-if="item.questions_count == 0" class="disabled">
                  لا يوجد أي سؤال 
                </td>
                <td>
                  <a href="javascript:"
                  @click="editLesson(item)"
                    ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                      ><i class="ti-pencil"></i></span
                  ></a>

                  <td>
                  <a href="javascript:"
                  @click="showQuestion(item)"
                    ><span class="badge rounded-pill c-light-blue-500"
                      ><i class="ti-eye"></i></span
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <loading v-show="loading" />
          <p v-show="!lessons.length" style="text-align: center">
            {{ $t("noData") }}
          </p>
          <div style="text-align:center;margin-top:40px">
          <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 2 " :per-page="10" @paginate="getLessons"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
    id: {
      type: String,
      required: true
    },
    chapter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      lessonName: "",
      lessonId: "",
      lessons: [],
      pagination: {},
      page:1,
    };
  },
  created() {
    this.getLessons();
  },
  methods: {
    showQuestion(lesson) {
      this.$router.push("/questions?id="+lesson.id+"&lesson="+this.chapter+"-"+lesson.name);
    },
    checkForm: function (e) {
      e.preventDefault();
      this.getLessons();
    },
    getLessons: function () {
      this.loading = true;
      this.$http
        .get("https://yalla-emtihan.com/backend/lessons/getLessons", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            lesson_id: this.lessonId,
            lesson_name: this.lessonName,
            chapter_id: this.id,
            page: this.page
          },
        })
        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.lessons = data.body.data;
                this.pagination = data.body;
                console.log(this.pagination);
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          }
        );
    },
    addLesson: function () {
      const panel1Handle = this.$showPanel({
        component: () => import("../../chapters/lessons/AddLessons.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          reload:this.getLessons,
          chapterId: this.id
        },
      });
    },
    editLesson(lesson) {
      const panel1Handle = this.$showPanel({
        component: () => import("../../chapters/lessons/EditLesson.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          lesson: lesson,
          reload:this.getLessons
        },
      });

      panel1Handle.promise.then((result) => {});
    },
    error: function (message) {
      this.$toast.error(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning: function (message) {
      this.$toast.warning(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    success: function (message) {
      this.$toast.success(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style>
</style>